label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.invitation-container.card {
  max-width: 600px !important;
  padding: 40px 40px;
}

.report-container.card {
  max-width: 800px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.theme-btn {
  font-weight: 600;
  line-height: 48px;
  background-color: #fec537;
  -webkit-box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  -moz-box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.theme-btn:hover {
  background-color: #fcb714;
  color: #fff;
}